var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"360"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[(_vm.user)?_c('v-card',{staticClass:"white--text"},[_c('v-card-title',{staticClass:"text-h2 indigo"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),(_vm.user.client)?_c('v-card-subtitle',{staticClass:"text-center pb-3 indigo white--text"},[_vm._v(" "+_vm._s(_vm.user.client.name)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"pt-0"},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-action',{staticClass:"my-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":("mailto:" + (_vm.user.email))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v(" mdi-email ")])],1)]}}],null,false,2614829044)},[_c('span',[_vm._v("לשליחת אימייל")])])],1),_c('v-list-item-content',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1),_c('v-list-item',[_c('v-list-item-action',{staticClass:"my-0"},[_c('v-row',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":("tel:" + (_vm.user.phone))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" mdi-phone ")])],1)]}}],null,false,1462161970)},[_c('span',[_vm._v("להתקשר")])]),(_vm.whatsapp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":("" + _vm.whatsapp)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green lighten-1"}},[_vm._v(" mdi-whatsapp ")])],1)]}}],null,false,2228911563)},[_c('span',[_vm._v("לשליחת WhatsApp")])]):_vm._e()],1)],1),_c('v-list-item-content',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.user.phone)+" ")])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"indigo"},on:{"click":function($event){return _vm.$router.push({ name: 'User', params: { slug: _vm.user.slug } })}}},[_vm._v(" מעבר לעמוד המשתמש ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":function($event){_vm.state = false}}},[_vm._v(" סגירה ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }