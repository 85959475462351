<template>
  <v-dialog v-model="state" max-width="360">
    <v-card v-if="user" class="white--text">
      <v-card-title class="text-h2 indigo">
        {{ user.name }}
      </v-card-title>
      <v-card-subtitle
        v-if="user.client"
        class="text-center pb-3 indigo white--text"
      >
        {{ user.client.name }}
      </v-card-subtitle>

      <v-card-text class="pt-0">
        <v-list two-line>
          <v-list-item>
            <v-list-item-action class="my-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    :href="`mailto:${user.email}`"
                    v-on="on"
                  >
                    <v-icon color="red lighten-1">
                      mdi-email
                    </v-icon>
                  </v-btn>
                </template>
                <span>לשליחת אימייל</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content class="py-0">
              {{ user.email }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action class="my-0">
              <v-row>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :href="`tel:${user.phone}`"
                      v-on="on"
                    >
                      <v-icon color="green darken-2">
                        mdi-phone
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>להתקשר</span>
                </v-tooltip>
                <v-tooltip v-if="whatsapp" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" :href="`${whatsapp}`" v-on="on">
                      <v-icon color="green lighten-1">
                        mdi-whatsapp
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>לשליחת WhatsApp</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" :href="`sms:${user.phone}`" v-on="on">
                      <v-icon color="blue lighten-1">
                        mdi-message-text-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>לשליחת SMS</span>
                </v-tooltip> -->
              </v-row>
            </v-list-item-action>
            <v-list-item-content class="py-0">
              {{ user.phone }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn color="indigo" @click="$router.push({ name: 'User', params: { slug: user.slug } })">
          מעבר לעמוד המשתמש
        </v-btn>
        <v-spacer />

        <v-btn color="grey darken-1" text @click="state = false">
          סגירה
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UserDialog',
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    state: false,
    // form: [],
  }),
  computed: {
    user () {
      let user = this.$store.getters['users/user'](this.email)
      user = { ...user }
      user.client = this.$store.getters['clients/client'](user.client)
      return user
    },
    whatsapp () {
      if (this.user && this.user.phone && this.user.phone.length === 10) {
        let phone = this.user.phone
        phone = phone.replace('05', '9725')
        return 'https://wa.me/' + phone
      }
      return null
    },
  },
  watch: {
    email (email) {
      if (email) {
        this.state = true
      }
    },
    state (state) {
      if (!state) this.$parent.userDialog = ''
    },
  },
  mounted () {
    // console.log('mounted', this.user)
    // this.setForm()
  },
  methods: {
    //
  },
}
</script>
